const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://q24kzbaxe5d23pdc4k47gc3wru.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "Auth": {
        "region": "us-east-2",
        "userPoolId": "us-east-2_cUG7zjtNC",
        "userPoolWebClientId": "2ok33pkf2qc702ve75k5prg2i9",
        "mandatorySignIn": true,
        "cookiestorage": {
            "domain": ".getvoxi.com",
            "expires": 30,
            "secure": true
        },
        "authenticationFlowType": "USER_PASSWORD_AUTH"
    },
    "Storage": {
        "AWSS3": {
            "bucket": "",
            "region": "us-east-2"
        }
    },
    "aws_user_pools_id": "",
    "aws_user_pools_web_client_id": "",
    "aws_cognito_identity_pool_id": "us-east-2:979d8d0a-3ccc-4f76-9392-a130818d7a3a",
    "aws_cognito_region": "us-east-2",
    "aws_mobile_analytics_app_id": "b9bf6eb3d18248a5b122c08b44eba355",
    "aws_mobile_analytics_app_region": "us-east-1"
}
    
export default awsmobile;